import { ApiReactiveService, type ApiServiceUseFetchOptions } from '@composable-api/service/api.reactive-service'
import { CategoryTreeModel } from '../models/category-tree.model'

class CategoriesTreeApiReactiveService<M extends CategoryTreeModel> extends ApiReactiveService<M> {
    useGetForMenu<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this
            // TODO: add support for reacting to locale changes?
            // .reactToLocaleChange()
            // TODO: add automatic handling of the only parameter to ApiService
            .onlyAttrs([
                CategoryTreeModel.ATTR_ID,
                CategoryTreeModel.ATTR_NAME,
                CategoryTreeModel.ATTR_CHILDREN,
            ])
            .embed([
                CategoryTreeModel.EMBED_URLS,
                CategoryTreeModel.EMBED_THUMBNAIL_IMAGE_URL,
            ])
            .useFetch({
                ...options,
                method: 'GET',
                ssrCache: {
                    key: 'categories-tree-menu',
                    ttl: 60 * 30,   // 30 min
                    swr: true,
                },
                excludeFromDataRefresh: true,
            })
    }
}

export default function useCategoriesTreeApiService() {
    return new CategoriesTreeApiReactiveService({
        endpoint: '/categories?tree',
    }, CategoryTreeModel)
}
