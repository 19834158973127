import { setResponseHeaders } from 'h3'
import { PageModel } from '@simploshop-models/page.model'
import type { MaybeRef, Ref } from 'vue'
import type { UseHeadInput } from 'unhead'
import type { MergeHead } from 'zhead'

/**
 * A composable that sets the response headers for the current page.
 * This can obviously only work on the server. When called on client-side,
 * it does nothing.
 *
 * It is a wrapper around the `setResponseHeaders` function from `h3` &
 * uses the `useRequestEvent` composable to get the current request event.
 */
export function useSetPageHeaders(page: MaybeRef<PageModel | null> | null): void
export function useSetPageHeaders(headers: Record<string, string | string[]>): void
export function useSetPageHeaders(...args: any[]) {
    if (args.length !== 1) return
    const arg = toValue(args[0])
    if (!arg) return
    if (!import.meta.server) return

    const event = useRequestEvent()
    if (!event) return

    let headers: Record<string, string | string[]>

    if (arg instanceof PageModel) {
        const modelHeaders = arg.headers
        if (!modelHeaders) return
        headers = modelHeaders
    } else {
        headers = arg
    }

    setResponseHeaders(event, headers)
}

interface UseSetPageSeoPropertiesOptions {
    /**
     * The title of the page. Can be a string or a function that returns a string.
     * (which will be passed to `computed`)
     *
     * Will get overridden if a title tag is already set on the page model.
     */
    title?: string | (() => string)
    /**
     * The description of the page. Can be a string or a function that returns a string.
     * (which will be passed to `computed`)
     *
     * Will get overridden if a description meta tag is already set on the page model.
     */
    description?: string | (() => string)
    /**
     * Additional config passed to `useHead()`
     */
    head?: UseHeadInput<MergeHead>
}

/**
 * A composable that sets all the SEO properties for a page.
 * The properties of the page model override the options.
 *
 * This composable is meant to be called only once, preferably in a
 * root layout.
 *
 * (For example, if the page model has a title, it will
 * override the title in the options.)
 * @param page the page model for the current page
 * @param options the options for the SEO properties
 */
export function useSetPageSeoProperties(page: Ref<PageModel | null> | null, options?: UseSetPageSeoPropertiesOptions) {
    const i18nHead = useLocaleHead({
        addSeoAttributes: true,
        addDirAttribute: true,
        identifierAttribute: 'id',
    })

    const title = typeof options?.title === 'function' ? computed(options.title) : options?.title
    const description = typeof options?.description === 'function' ? computed(options.description) : options?.description

    const isDescriptionMetaTagSet = computed(() => page?.value?.metaTags?.some(tag => 'name' in tag && tag.name === 'description'))

    useHead({
        htmlAttrs: {
            lang: i18nHead.value.htmlAttrs?.lang,   // i18n seo language
            dir: i18nHead.value.htmlAttrs?.dir,     // i18n seo direction
            ...(options?.head?.htmlAttrs || {}),
        },
        script: () => [
            ...(page?.value?.structuredData
                ? [{
                    type: 'application/ld+json',
                    innerHTML: page.value?.structuredData,
                }]
                : []),
        ],
        title: () => page?.value?.fullTitle || (!title ? null : typeof title === 'string' ? title : title.value),
        link: () => [
        // ...(i18nHead.value.link || []),     // i18n seo link tags
            ...(page?.value?.links || []),
            ...(options?.head?.link || []),
        ],
        meta: () => [
            ...(!isDescriptionMetaTagSet.value && description ? [{ name: 'description', content: typeof description === 'string' ? description : description.value }] : []),
            ...(page?.value?.metaTags || []),    // backend meta tags
            // ...(i18nHead.value.meta || []),     // i18n seo meta tags
            { name: 'viewport', content: 'width=device-width, initial-scale=1, viewport-fit=cover' },
            ...(options?.head?.meta || []),
        ],
    })

    useSetPageHeaders({
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': ' ',
        'X-Frame-Options': 'SAMEORIGIN',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer, strict-origin-when-cross-origin',
        'Permissions-Policy': 'camera=(self), display-capture=(self), fullscreen=(self), geolocation=(*), microphone=(self)',
    })

    useSetPageHeaders(page)
}
