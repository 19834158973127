<template>
    <div class="flex">
        <NuxtLink to="https://simplo.cz" class="sim-created-by">
            Created by
            <CoreIconSimplo width="66" height="20" />
        </NuxtLink>
    </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

.sim-created-by {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
}

</style>
