<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 139 37"
         width="139"
         height="38"
         focusable="false"
    >
        <path :class="letterFillColorClass"
              d="M10.692 17.26c-1.235-.55-2.535-.905-3.9-.905-.942 0-1.657.194-2.145.518-.52.323-.747.808-.747 1.39 0 1.615 2.47 2.068 4.647 3.07 1.723.776 3.217 1.875 3.217 4.105 0 1.81-.78 3.07-1.917 3.911-1.397 1.067-3.38 1.422-5.005 1.422-2.242 0-3.574-.355-4.842-.84l.39-2.844c1.333.614 2.73.97 4.29.97.975 0 1.95-.194 2.6-.615.585-.355.91-.872.91-1.648 0-1.616-2.47-2.069-4.583-3.103-1.69-.808-3.282-1.94-3.282-4.267 0-1.487.553-2.618 1.56-3.426 1.137-.938 2.762-1.422 4.712-1.422 1.788 0 3.218.388 4.517 1.002l-.422 2.683Zm4.026-6.053v-3.62h3.64v3.62h-3.64Zm0 19.08v-16.13h3.64v16.13h-3.64Zm28.917 0v-8.695c0-1.552-.13-2.812-.65-3.653-.552-.97-1.625-1.358-2.957-1.358-1.3 0-2.535.292-3.965 1.003.325.775.487 1.745.487 2.909v9.794h-3.64v-8.695c0-1.552-.13-2.812-.65-3.653-.552-.97-1.592-1.358-2.924-1.358-1.202 0-2.567.195-3.575.388v13.318h-3.64V14.546c2.21-.518 4.973-.97 7.507-.97 1.95 0 3.543.388 4.713 1.422 2.21-1.002 3.997-1.422 6.37-1.422 2.307 0 3.997.646 5.069 1.971 1.04 1.196 1.495 2.812 1.495 4.946v9.794h-3.64Zm28.752-5.043c0 2.069.812 2.489 1.885 2.489.422 0 .877-.064 1.202-.194l.618 2.812c-.716.259-1.658.42-2.698.42-1.885 0-4.647-.743-4.647-5.397V7.37l3.64-.55v18.424Zm16.012-11.262a8.721 8.721 0 0 0-2.611-.398c-4.797 0-8.686 3.867-8.686 8.638s3.889 8.64 8.686 8.64c4.797 0 8.686-3.868 8.686-8.64a8.564 8.564 0 0 0-.5-2.866c-3.196-.801-4.83-2.904-5.575-5.374ZM85.788 27.26c-2.793 0-5.065-2.26-5.065-5.038 0-2.777 2.272-5.037 5.065-5.037 2.792 0 5.064 2.26 5.064 5.037 0 2.778-2.272 5.038-5.064 5.038ZM56.743 13.545a9.18 9.18 0 0 0-5.86 2.111l-.269.224V37h3.166v-6.591c.951.326 1.943.49 2.963.49 4.955 0 8.986-3.893 8.986-8.678 0-4.784-4.03-8.676-8.986-8.676Zm0 14.297a5.908 5.908 0 0 1-2.963-.79v-9.66a5.909 5.909 0 0 1 2.963-.79c3.21 0 5.821 2.521 5.821 5.62 0 3.098-2.61 5.62-5.821 5.62Z"
        />
        <path fill="#FF0059"
              d="M122.854 8.728a3.225 3.225 0 0 0-2.903-3.522 3.233 3.233 0 0 0-3.541 2.887 3.225 3.225 0 0 0 2.903 3.522 3.233 3.233 0 0 0 3.541-2.887Zm10.531-.289c0-1.88-1.584-3.409-3.532-3.409a3.58 3.58 0 0 0-1.797.479v5.862a3.59 3.59 0 0 0 1.797.478c1.948 0 3.532-1.53 3.532-3.41Z"
        />
        <path fill="#FF0059"
              d="M132.013 0h-25.488v3.62c.796-.194 1.631-.349 2.467-.349 1.282 0 2.369.31 3.127 1.063.68.696 1.01 1.739 1.01 3.072v5.854h-2.156V8.082c0-1.005-.097-1.797-.505-2.318-.408-.502-1.069-.696-1.943-.696-.699 0-1.418.155-2 .31v7.882h-2.176V0H91.024v9.997c0 3.834 3.126 6.943 6.98 6.943h28.131V4.592l.163-.136a5.57 5.57 0 0 1 3.555-1.281c3.007 0 5.453 2.361 5.453 5.264s-2.446 5.265-5.453 5.265c-.618 0-1.22-.1-1.797-.297v3.533H139V6.95c0-3.84-3.128-6.95-6.987-6.95Zm-30.378 12.7c-.835.637-2.02.85-2.991.85-1.34 0-2.137-.213-2.894-.503l.233-1.7c.796.367 1.632.58 2.564.58.583 0 1.165-.116 1.554-.367.349-.213.544-.522.544-.986 0-.966-1.477-1.236-2.74-1.854-1.01-.483-1.961-1.16-1.961-2.55 0-.89.33-1.565.932-2.048.68-.56 1.651-.85 2.817-.85 1.068 0 1.923.231 2.7.599l-.253 1.603c-.738-.328-1.515-.541-2.33-.541-.564 0-.991.116-1.282.31-.311.192-.447.482-.447.83 0 .966 1.476 1.236 2.777 1.835 1.03.464 1.923 1.12 1.923 2.454 0 1.082-.466 1.835-1.146 2.338Zm17.997.952c-2.91 0-5.27-2.347-5.27-5.241 0-2.895 2.36-5.242 5.27-5.242 2.91 0 5.27 2.347 5.27 5.242 0 2.894-2.36 5.24-5.27 5.24Z"
        />
        <!--  DEMO text  -->
        <path
            v-if="variant === 'header'"
            :class="letterFillColorSecondaryClass"
            d="M101.01,28.91h1.62c.42,0,.8-.07,1.15-.21.36-.15.68-.36.94-.62.27-.27.48-.58.62-.93.15-.35.23-.74.23-1.15s-.08-.8-.23-1.15c-.15-.36-.36-.68-.62-.94-.27-.27-.58-.47-.94-.62-.35-.15-.74-.23-1.15-.23h-1.62v5.87ZM99.09,30.63v-9.29h3.57c.7,0,1.34.12,1.94.36.59.23,1.11.56,1.55.98s.79.92,1.04,1.49c.26.57.38,1.18.38,1.83s-.12,1.27-.37,1.83c-.25.56-.6,1.05-1.05,1.47-.44.42-.96.74-1.55.98-.59.23-1.24.35-1.94.35h-3.57ZM109.02,30.63v-9.29h7.14v1.67h-5.22v2.12h3.44v1.62h-3.44v2.2h5.27v1.67h-7.19ZM117.7,30.63v-9.29h2.47l2.55,5.02,2.55-5.02h2.47v9.29h-1.82v-6.83l-3.22,6.17-3.21-6.2v6.86h-1.78ZM134.09,30.77c-.69,0-1.33-.12-1.92-.36-.59-.25-1.11-.59-1.57-1.02-.44-.44-.79-.95-1.05-1.53-.25-.58-.37-1.21-.37-1.88s.12-1.3.37-1.87c.26-.58.61-1.09,1.05-1.53.45-.44.97-.78,1.57-1.02.59-.25,1.23-.37,1.92-.37s1.33.12,1.92.37c.6.24,1.12.58,1.57,1.02.45.43.8.94,1.05,1.53.25.58.37,1.2.37,1.87s-.12,1.3-.37,1.88c-.25.58-.6,1.08-1.05,1.53-.44.43-.96.77-1.57,1.02-.59.24-1.23.36-1.92.36ZM134.09,29.01c.42,0,.81-.08,1.17-.23.35-.16.66-.37.93-.64.27-.27.47-.6.62-.97.15-.37.23-.77.23-1.19s-.08-.83-.23-1.19c-.15-.37-.36-.69-.62-.96-.27-.27-.58-.49-.93-.64-.35-.16-.74-.24-1.17-.24s-.8.08-1.15.24c-.35.15-.66.36-.93.64-.27.27-.47.58-.62.96-.15.36-.23.76-.23,1.18s.08.84.23,1.21c.15.37.36.69.62.97.27.27.58.48.93.64.35.15.74.23,1.15.23Z"
        />
    </svg>
</template>

<script lang="ts" setup>

const {
    dark = true,
    light,
    variant = 'general',
} = defineProps<{
    dark?: boolean
    light?: boolean
    variant?: 'header' | 'general'
}>()

const letterFillColorClass = dark && !light ? 'c-primary-dark' : 'c-primary-light'
const letterFillColorSecondaryClass = dark && !light ? 'c-secondary-dark' : 'c-secondary-light'

</script>

<style lang="scss" scoped>

.c-primary-dark {
    fill: #29004F;
}

.c-primary-light {
    fill: #fff;
}

.c-secondary-dark {
    fill: $sim-c-basic-500;
}

.c-secondary-light {
    fill: $sim-c-basic-400;
}

</style>
