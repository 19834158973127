<template>
    <CoreContainer v-if="isShown" tag="div" class="bg-primary-900 text-white">
        <BaseContainerContent class="flex items-start justify-between gap-4 md:!py-7">
            <div>
                <slot />
            </div>

            <UiButtonClose
                variant="dark"
                @click="close"
            />
        </BaseContainerContent>
    </CoreContainer>
</template>

<script lang="ts" setup>
import type { NavbarNoticeId } from '../../assets/ts/enums/navbar-notice'

const {
    noticeId,
} = defineProps<{
    /**
     * A unique identifier of the notice.
     * This is used to identify the notice in the cookie.
     */
    noticeId: NavbarNoticeId
}>()

/**
 * The cookie used to hide the notice.
 * Template: `hide-n425=1` to hide notice with `id 425`.
 * If the cookie is **not set** or is **not equal** to `1`, the notice is shown.
 */
const noticeCookie = useCookie<number>(`hide-n${noticeId}`, {
    // expires in 2 weeks
    maxAge: 60 * 60 * 24 * 7 * 2,
})

const isShown = computed(() => !noticeCookie.value || noticeCookie.value !== 1)

function close() {
    noticeCookie.value = 1
}

</script>

<style lang="scss" scoped>

</style>
